<template>
	<div class="home">
        <div class="404-container py-3">
            <h1>{{$t("404.title")}}</h1>
            <p>{{$t("404.description")}}</p>
            
        </div>
	</div>
</template>

<script>
	export default {
		name: 'NotFound',
	};
</script>
